import { classNames } from '@/components/shared/classNames'
import styles from './Paragraph.module.scss'

interface Props {
  tag?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'label' | 'div' | 'figcaption'
  spanColor?: 'none' | 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'error'
  className?: string
  children: React.ReactNode
}

const Paragraph = ({ tag = 'p', className, spanColor = 'none', children }: Props) => {
  const Tag = tag
  return <Tag className={classNames(styles.Paragraph, styles[`childspan-${spanColor}`], className)}>{children}</Tag>
}

export default Paragraph
