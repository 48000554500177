'use client'
import { SessionData } from '@/app/api/auth/types'
import Loader from '@/components/atoms/Loader'
import { Alert } from '@/components/atoms/alerts/Alert'
import { useSubmitForm } from '@/components/atoms/forms/FormUtils'
import { RequestedFormsConfigType, useMergeFormsConfig } from '@/components/atoms/forms/InputConfigs'
import { signIn, signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import { HTMLAttributes, useEffect, useState } from 'react'
import BaseFormStyled from '../elements/BaseFormStyled'
import FormBody from '../elements/FormBody'

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

const LoginForm = ({ className, ...props }: Props) => {
  const { data: session, status } = useSession()
  const router = useRouter()
  const searchParams = useSearchParams()
  const baseFormInputConfigurations: RequestedFormsConfigType = [
    {
      name: 'username',
      className: 'flex-1 text-sm',
      label: 'Email',
      placeholder: 'Email'
    },
    {
      name: 'password',
      className: 'flex-1 text-sm',
      label: 'Contraseña',
      placeholder: 'Contraseña'
    }
  ]
  const [errors, setErrors] = useState<Record<string, string>>({})

  const inputConfiguration = useMergeFormsConfig(baseFormInputConfigurations)

  const handleSignIn = async ({ username, password }: Record<string, string>) => {
    const result = await signIn('credentials', {
      email: username,
      password,
      redirect: false
    })

    if (result?.error) {
      setErrors({ username: 'Usuario o contraseña incorrectos' })
    } else {
      router.push('/')
    }
  }

  const { handleSubmit, isLoading } = useSubmitForm({
    service: handleSignIn,
    aditionalPayloadData: {},
    onErrorCallback: () => {
      console.log('Sign-in failed')
    },
    onSuccessCallback: () => {
      console.log('Sign-in successful')
    }
  })

  const message = isLoading ? 'Cargando...' : 'Iniciar sesión'

  useEffect(() => {
    const error = searchParams.get('error')
    if (error) {
      setErrors({ username: 'Usuario o contraseña incorrectos' })
    }
  }, [])

  useEffect(() => {
    console.log(status)
    const user = session?.user as SessionData | undefined
    console.log(user)
    if (user) {
      router.push('/')
    }
    // Commented out code for handling different user states
    // if (user && user.nextStep === 'DONE') {
    //   router.push('/')
    // } else if (user && user.nextStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
    //   signOut({ redirect: false }).then(() => {
    //     router.push('/auth/user-confirmation')
    //   })
    // }
  }, [session, router])

  return (
    <div className={className} {...props}>
      {isLoading && <Loader />}
      <FormBody className="flex flex-col gap-3">
        {errors.username && <Alert theme="error">{errors.username}</Alert>}

        <BaseFormStyled
          className="flex-1"
          InputsConfig={inputConfiguration}
          onSubmit={handleSubmit}
          submitText={message}
          submitClassName="text-lg w-full"
          extraFromItem={
            <div className="flex justify-end w-full -mt-1 mb-2">
              <Link href="/auth/password-recovery" className="text-primary font-semibold hover:underline">
                ¿Olvidaste tu contraseña?
              </Link>
            </div>
          }
        />
      </FormBody>
    </div>
  )
}

export default LoginForm
